import { Cloud, DoDisturb, PictureAsPdf, CoPresent } from "@mui/icons-material"
import { ListItemIcon, ListItemText } from "@mui/material"
import { AdobeLicense } from "../apiFunctions"

interface LicenseType {
  title: string
  description: string
  icon: React.ReactNode
}

export const getLicenseData = (license: AdobeLicense): LicenseType => {
  switch (license) {
    case AdobeLicense.CreativeCloud:
      return {
        title: "Adobe Creative Cloud",
        description:
          "A collection of apps for photography, video, design, web, UX, and social media",
        icon: <Cloud></Cloud>,
      }
    case AdobeLicense.AcrobatDC:
      return {
        title: "Adobe Acrobat Professional",
        description:
          "Make your job easier with the trusted PDF editor and creator",
        icon: <PictureAsPdf></PictureAsPdf>,
      }
    case AdobeLicense.SharedDevice:
      return {
        title: "Shared Device License",
        description:
          "Shared Device License assigned by a CSC/OIT or on directed by them",
        icon: <CoPresent></CoPresent>,
      }
    case AdobeLicense.None:
      return {
        title: "No License",
        description: "No Adobe License Assigned",
        icon: <DoDisturb></DoDisturb>,
      }
  }
}

interface LicenseDescriptionProps {
  license: AdobeLicense
}

export const LicenseDescription = ({ license }: LicenseDescriptionProps) => {
  const data = getLicenseData(license)

  return (
    <>
      <ListItemIcon>{data.icon}</ListItemIcon>
      <ListItemText
        primary={data.title}
        secondary={data.description}
      ></ListItemText>
    </>
  )
}
