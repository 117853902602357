import { Alert, AlertTitle, Link, Typography } from "@mui/material"
import { LicenseEligibility } from "../apiFunctions"

interface IneligibleReasonProps {
  eligibility: LicenseEligibility
}

export const IneligibleReason = ({ eligibility }: IneligibleReasonProps) => {
  return (
    <>
      <Alert severity="warning">
        <AlertTitle>
          <strong>Ineligible for an Adobe License from OIT</strong>
        </AlertTitle>
        <Typography>
          {eligibility.reason_name === "STUDENT_OR_APPLICANT" ? (
            <>
              Unfortunately, students who are not employed by the university are
              ineligible for a license.
            </>
          ) : eligibility.reason_name === "ACTIVE_EMPLOYEE_UCI_HEALTH" ? (
            <>
              UCI Health maintains a separate license agreement with Adobe.
              Individuals who work for the College of Health Sciences or the
              Medical Center cannot get a license from OIT and should instead{" "}
              <Link href="https://ucihealth.service-now.com/itportal?id=sc_cat_item&sys_id=1cd768ab1bd07190b7549683b24bcb12&sysparm_category=d258b953c611227a0146101fb1be7c31">
                submit a request
              </Link>{" "}
              for a license using this form.
            </>
          ) : (
            <>
              Unfortunately, we cannot process your request at this time. Please
              try again or{" "}
              <Link href="mailto:oit@uci.edu?subject=Adobe License Manager error - Failed to Load User's Current License">
                contact the Help Desk
              </Link>
              .
            </>
          )}
          <br></br>
          <br></br>
          <Link href="https://www.oit.uci.edu/adobe/">Visit our FAQ</Link> for
          more information about Adobe software available from OIT.
        </Typography>
      </Alert>
    </>
  )
}
