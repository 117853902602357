import { Check } from "@mui/icons-material"
import {
  Collapse,
  Stack,
  CircularProgress,
  Typography,
  Container,
  Avatar,
  Button,
  Alert,
  Link,
} from "@mui/material"
import { green } from "@mui/material/colors"
import { AdobeLicense } from "../apiFunctions"
import { useState } from "react"

interface LicenseSubmissionProps {
  selectedLicense: AdobeLicense
  handleFinish: () => void
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
}

function LicenseSubmission({
  selectedLicense,
  handleFinish,
  isLoading,
  isSuccess,
  isError,
}: LicenseSubmissionProps) {
  const [isOpen, setIsOpen] = useState(true)
  // if all values are false, there was never a mutation (pro license)
  if (!isLoading && !isSuccess && !isError) {
    isSuccess = true
  }

  return (
    <Collapse in={isOpen}>
      <Stack spacing={3} alignItems="center" justifyContent="center">
        {isLoading && (
          <>
            <CircularProgress></CircularProgress>
            <Typography hidden={selectedLicense === AdobeLicense.SharedDevice}>
              Changing Your License
            </Typography>
            <Typography hidden={selectedLicense !== AdobeLicense.SharedDevice}>
              Requesting Your License
            </Typography>
            <Container fixed maxWidth="xs">
              <img
                style={{
                  maxWidth: "100%",
                }}
                src="/img/PeterAnteater_Computer.png"
                alt="Cartoon drawing of Peter the Anteater in front of a laptop"
              ></img>
            </Container>
          </>
        )}
        {isSuccess && (
          <>
            <Avatar sx={{ backgroundColor: green[500] }}>
              <Check></Check>
            </Avatar>
            <Typography hidden={selectedLicense === AdobeLicense.SharedDevice}>
              Successfully changed your license!
            </Typography>
            <Typography hidden={selectedLicense !== AdobeLicense.SharedDevice}>
              Success!
            </Typography>
            <Container fixed maxWidth="xs">
              <img
                style={{
                  maxWidth: "100%",
                }}
                src="/img/PeterAnteater_Key_WatchWaving.png"
                alt="Cartoon drawing of Peter the Anteater waving to you in front of a laptop"
              ></img>
            </Container>
            {selectedLicense !== AdobeLicense.None && (
              <Button
                variant="contained"
                href="https://uci.service-now.com/sp?id=kb_article&sysparm_article=KB0012283"
                target="_blank"
              >
                Installation / Setup Instructions
              </Button>
            )}
            <Button
              onClick={() => {
                handleFinish()
                setIsOpen(false)
              }}
            >
              Finish
            </Button>
          </>
        )}
        {isError && (
          <Alert severity="error">
            <Typography>
              An Error Occurred While Changing Your License.
              <br></br>
              Please reload the page and try again or{" "}
              <Link href="mailto:oit@uci.edu?subject=Adobe License Manager error - Failed to Change a User's License">
                Contact the Help Desk
              </Link>
              .
            </Typography>
          </Alert>
        )}
      </Stack>
    </Collapse>
  )
}

export default LicenseSubmission
