import {
  Alert,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Link,
  Typography,
} from "@mui/material"

import { useEffect, useState } from "react"

interface ProConfirmationDialogProps {
  open: boolean
  setOpen: (open: boolean) => void
  onConfirm: (ucinetid: string) => void
}

const ProConfirmationDialog = (props: ProConfirmationDialogProps) => {
  const { open, setOpen, onConfirm } = props
  const [confirmDisabled, setConfirmDisabled] = useState(true)
  const [tosChecked, setTosChecked] = useState(false)
  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    if (tosChecked) {
      setConfirmDisabled(false)
    } else {
      setConfirmDisabled(true)
    }
    return () => {
      setConfirmDisabled(true)
    }
  }, [tosChecked])

  const handleClose = () => {
    setTosChecked(false)
    setShowAlert(false)
    setOpen(false)
  }

  const handleConfirm = () => {
    if (!tosChecked) {
      setShowAlert(true)
    } else {
      onConfirm("")
      handleClose()
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Do you need Acrobat Pro?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          If you just need to open PDFs, click "Cancel" and use either the free
          Acrobat Reader or any browser, such as Chrome, Firefox, Edge, etc. If
          you are conducting university business and need to EDIT PDFs, you may
          proceed. While the cost of this software will not be charged directly
          to your department, it is not free. It is being centrally funded for
          this fiscal year only, and this is subject to change in the future.
          For this reason, please make sure your supervisor or fiscal officer is
          aware that you are requesting this license.
        </DialogContentText>

        <Card sx={{ my: "1rem" }}>
          <CardContent>
            <Typography variant="h6">
              <b>Terms and Conditions</b>
            </Typography>
            <Typography sx={{ mb: "0.75rem" }}>
              The license holder agrees that they will NOT store P3 or P4 data
              on their local machines, on-premise servers, or cloud servers
              without first working closely with their CSCs and/or OIT Security.
              Please review our (
              <Link
                target="_blank"
                href="https://uci.service-now.com/sp?id=kb_article_view&sysparm_article=KB0013018"
              >
                KB article
              </Link>
              ) to understand what counts as sensitive data. If you have any
              questions about this, please email oit@uci.edu and we will assign
              the ticket to our Security Team.
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={tosChecked}
                  onClick={() => {
                    setTosChecked(!tosChecked)
                  }}
                />
              }
              label={
                <Typography sx={{ fontSize: "0.85em" }}>
                  I am purchasing this license for <b>myself</b> and agree with
                  the <b>Terms and Conditions</b>.
                </Typography>
              }
              required
            />
          </CardContent>
        </Card>
        {showAlert && (
          <Alert severity="error">
            Please enter a valid UCINetID and then agree with the Terms of
            Conditions!
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button disabled={confirmDisabled} onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ProConfirmationDialog
