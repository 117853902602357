import { Hub } from "@mui/icons-material"
import { AppBar, Toolbar, Typography } from "@mui/material"

export const Header = () => {
  return (
    <>
      <AppBar
        sx={{
          background:
            "linear-gradient(90deg, rgba(253,0,79,1) 0%, rgba(2,149,255,1) 100%);",
        }}
      >
        <Toolbar>
          <Hub sx={{ marginRight: 3 }}></Hub>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Adobe License Manager
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar></Toolbar>
    </>
  )
}
