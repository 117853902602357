import {
  Alert,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Link,
  TextField,
  Typography,
} from "@mui/material"
import { styled } from "@mui/material/styles"

import { useEffect, useState } from "react"

interface CCConfirmationDialogProps {
  open: boolean
  setOpen: (open: boolean) => void
  onConfirm: (kfsNumber: string) => void
}

const ConfirmationTextField = styled(TextField)({
  "& .MuiInput-underline:after": {
    borderBottomColor: "#E30047",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#5a001c",
    },
    "&:hover fieldset": {
      borderColor: "#88002a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#b50038",
    },
  },
})

const CCConfirmationDialog = (props: CCConfirmationDialogProps) => {
  const { open, setOpen, onConfirm } = props
  const [confirmDisabled, setConfirmDisabled] = useState(true)
  const [kfsNumber, setKfsNumber] = useState("")
  const [tosChecked, setTosChecked] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [isProperKFS, setIsProperKFS] = useState(false)
  const [hasFailedOnce, setHasFailedOnce] = useState(false)

  useEffect(() => {
    if (tosChecked) {
      setConfirmDisabled(false)
    } else {
      setConfirmDisabled(true)
    }
    return () => {
      setConfirmDisabled(true)
    }
  }, [tosChecked])

  const handleClose = () => {
    setKfsNumber("")
    setTosChecked(false)
    setShowAlert(false)
    setOpen(false)
    setHasFailedOnce(false)
  }

  const handleConfirm = () => {
    if (!isProperKFS || !tosChecked) {
      setShowAlert(true)
      setHasFailedOnce(true)
    } else {
      onConfirm(kfsNumber)
      handleClose()
    }
  }

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const re = new RegExp("^[A-Za-z][A-Za-z].{5,}$")
    setKfsNumber(event.target.value)
    if (re.test(event.target.value)) {
      setIsProperKFS(true)
    } else {
      setIsProperKFS(false)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Please enter a KFS account number</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Adobe Creative Cloud costs $180 and will be billed to the KFS account
          number you'll provide below. You can get this account number from your
          supervisor/office manager/fiscal officer. The license is valid for the
          current fiscal year only and will be renewed annually by your
          department.
        </DialogContentText>
        <ConfirmationTextField
          autoFocus
          margin="dense"
          id="kfs-number"
          label="KFS Number"
          placeholder="The KFS number format is [A-Z][A-Z]#####. (e.g. GF12345)."
          type="text"
          fullWidth
          variant="standard"
          value={kfsNumber}
          required
          error={hasFailedOnce}
          onChange={handleTextChange}
        />
        <Card sx={{ my: "1rem" }}>
          <CardContent>
            <Typography variant="h6">
              <b>Terms and Conditions</b>
            </Typography>
            <Typography sx={{ mb: "0.75rem" }}>
              The license holder agrees that they will NOT store P3 or P4 data
              on their local machines, on-premise servers, or cloud servers
              without first working closely with their CSCs and/or OIT Security.
              Please review our (
              <Link
                target="_blank"
                href="https://uci.service-now.com/sp?id=kb_article_view&sysparm_article=KB0013018"
              >
                KB article
              </Link>
              ) to understand what counts as sensitive data. If you have any
              questions about this, please email oit@uci.edu and we will assign
              the ticket to our Security Team.
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={tosChecked}
                  onClick={() => {
                    setTosChecked(!tosChecked)
                  }}
                />
              }
              label={
                <Typography sx={{ fontSize: "0.85em" }}>
                  I am purchasing this license for <b>myself</b> and agree with
                  the <b>Terms and Conditions</b>.
                </Typography>
              }
              required
            />
          </CardContent>
        </Card>
        {showAlert && (
          <Alert severity="error">
            Please use a valid KFS Number and then agree with the Terms of
            Conditions!
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button disabled={confirmDisabled} onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CCConfirmationDialog
