import { Grid, Link, Typography } from "@mui/material"

export const Footer = () => {
  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item>
        <Typography variant="caption" textAlign="center">
          <Link href="mailto:oit@uci.edu?subject=Adobe License Manager - General">
            OIT Software Licensing
          </Link>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption" textAlign="center">
          <Link href="https://uci.edu/privacy/">Privacy Notice</Link>
        </Typography>
      </Grid>
    </Grid>
  )
}
