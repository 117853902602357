import { Box, createTheme, CssBaseline, ThemeProvider } from "@mui/material"
import { pink } from "@mui/material/colors"
import { QueryClient, QueryClientProvider } from "react-query"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { Footer } from "./Footer"
import { Header } from "./Header"
import { Main } from "./Main"

const theme = createTheme({
  typography: {
    fontFamily: "Nunito, Arial",
  },
  palette: {
    primary: pink,
  },
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 15 * 60 * 1000, // 15 Minutes
    },
  },
})

export const App = () => {
  return (
    <CssBaseline>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              height="100%"
            >
              <Header></Header>
              <Routes>
                <Route path="/" element={<Main></Main>}></Route>
              </Routes>
              <Box display="flex" justifyContent="center" paddingBottom="1rem">
                <Footer></Footer>
              </Box>
            </Box>
          </BrowserRouter>
        </QueryClientProvider>
      </ThemeProvider>
    </CssBaseline>
  )
}
