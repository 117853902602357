import { useQuery } from "react-query"
import {
  fetchUserInfo,
  fetchEligibility,
  fetchCurrentLicense,
  checkCsc,
} from "../apiFunctions"
import React from "react"
import {
  Alert,
  Box,
  CircularProgress,
  Collapse,
  Container,
  Link,
} from "@mui/material"
import { IneligibleReason } from "./IneligibleReason"
import { LicenseManagementMain } from "./LicenseManagementMain"

export const Main = () => {
  let {
    isLoading: loginLoading,
    isError: loginError,
    data: userInfo,
  } = useQuery("user-info", fetchUserInfo, {
    staleTime: 30 * 1000, // 30 Seconds
    refetchOnWindowFocus: true,
  })

  let {
    isLoading: eligibilityLoading,
    isError: eligibilityError,
    data: eligibility,
  } = useQuery("eligibility", fetchEligibility, { enabled: userInfo !== null })

  let {
    isLoading: currentLicenseLoading,
    isError: currentLicenseError,
    data: currentLicense,
  } = useQuery("current-license", fetchCurrentLicense, {
    enabled: Boolean(eligibility?.eligible),
  })

  let {
    isLoading: checkCscLoading,
    isError: checkCscError,
    data: isCsc,
  } = useQuery("check-csc", checkCsc, {
    enabled: Boolean(eligibility?.eligible),
  })

  // Redirects users if they aren't logged in
  React.useEffect(() => {
    if (!loginLoading && userInfo === null) {
      if (process.env.NODE_ENV === "development") {
        alert(
          "You'll need to copy a valid 'alm_auth' cookie from the server or you'll get a fun login loop :p"
        )
      } else {
        window.location.pathname = "/saml/login"
      }
    }
  }, [loginLoading, userInfo])

  const isLoading =
    loginLoading ||
    eligibilityLoading ||
    currentLicenseLoading ||
    checkCscLoading
  const isError = loginError || eligibilityError || currentLicenseError

  // Csc user group check error should not stop app from functioning
  if (checkCscError) {
    isCsc = false
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      {isLoading || isError || !eligibility?.eligible ? (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Container maxWidth="sm">
            {isLoading ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress></CircularProgress>
              </Box>
            ) : isError ? (
              <>
                {loginError ? (
                  <Alert severity="error">
                    There was an issue checking your login status. Please reload
                    the page and try again.
                  </Alert>
                ) : eligibilityError ? (
                  <Alert severity="error">
                    There was an issue checking your eligibility for an Adobe
                    license. Please reload the page and try again or{" "}
                    <Link href="mailto:oit@uci.edu?subject=Adobe License Manager error - Failed to Determine a User's Eligibility For A License">
                      contact the Help Desk
                    </Link>
                    .
                  </Alert>
                ) : currentLicenseError ? (
                  <Alert severity="error">
                    There was an issue getting your current license. Please try
                    again or{" "}
                    <Link href="mailto:oit@uci.edu?subject=Adobe License Manager error - Failed to Determine a User's Eligibility For A License">
                      contact the Help Desk
                    </Link>
                    .
                  </Alert>
                ) : (
                  <>Something went really wrong :(</>
                )}
              </>
            ) : !eligibility?.eligible ? (
              <Collapse appear in>
                {eligibility !== undefined && (
                  <IneligibleReason
                    eligibility={eligibility}
                  ></IneligibleReason>
                )}
              </Collapse>
            ) : (
              <>
                Something has gone really wrong if you're seeing this message.
                Please reload the page and try again.
              </>
            )}
          </Container>
        </Box>
      ) : (
        currentLicense !== undefined && (
          <LicenseManagementMain
            currentLicense={currentLicense}
            isCsc={isCsc}
          ></LicenseManagementMain>
        )
      )}
    </Box>
  )
}
