import {
  Button,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material"
import { useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { AdobeLicense, setLicense } from "../apiFunctions"
import { getLicenseData } from "./LicenseDescription"
import CCConfirmationDialog from "./CCConfirmationDialog"
import ProConfirmationDialog from "./ProConfirmationDialog"
import LicenseSubmission from "./LicenseSubmission"

const LICENSE_SELECTION_REASONS = {
  [AdobeLicense.CreativeCloud]:
    "Choose this license if you absolutely need to use the other apps in the suite like Photoshop, Dreamweaver, etc. Note that Acrobat is included.",
  [AdobeLicense.AcrobatDC]:
    "Choose this license if you just need a tool to create and edit PDFs.",
  [AdobeLicense.SharedDevice]:
    "Choose this license ONLY if you are a CSC or were directed by a CSC or OIT to acquire this license. It will only work on specific machines.",
  [AdobeLicense.None]: "Return your unused license so someone else can use it.",
}

interface ChangeLicenseProps {
  currentLicense: AdobeLicense
  goBack: () => void
}

export const ChangeLicense = ({
  currentLicense,
  goBack,
}: ChangeLicenseProps) => {
  const queryClient = useQueryClient()
  const [selectedLicense, setSelectedLicense] = useState(currentLicense)
  const [submitted, setSubmitted] = useState(false)

  const [ccDialogOpen, setCcDialogOpen] = useState(false)
  const [proDialogOpen, setProDialogOpen] = useState(false)

  const mutation = useMutation(setLicense, {
    onMutate: async (params) => {
      await queryClient.cancelQueries("current-license")

      const previousLicense = queryClient.getQueryData("current-license")

      queryClient.setQueryData("current-license", params.license)

      return { previousLicense }
    },
    onSuccess: () => {
      queryClient.invalidateQueries("current-license")
    },
  })

  const handleSave = () => {
    if (selectedLicense === AdobeLicense.CreativeCloud) {
      setCcDialogOpen(true)
    } else if (selectedLicense === AdobeLicense.AcrobatDC) {
      setProDialogOpen(true)
    } else {
      setSubmitted(true)
      mutation.mutate({ license: selectedLicense, kfsNumber: "" })
    }
  }

  const handleConfirm = (kfsNumber: string) => {
    setSubmitted(true)
    // API handles NUL vs SDL when passing license
    mutation.mutate({ license: selectedLicense, kfsNumber: kfsNumber })
  }

  const handleFinish = () => {
    setSubmitted(false)
    goBack()
  }

  return (
    <>
      <CCConfirmationDialog
        open={ccDialogOpen}
        setOpen={setCcDialogOpen}
        onConfirm={handleConfirm}
      />
      <ProConfirmationDialog
        open={proDialogOpen}
        setOpen={setProDialogOpen}
        onConfirm={handleConfirm}
      />

      <Collapse in={!submitted}>
        <Typography>Please select your desired license:</Typography>
        <List>
          {[
            AdobeLicense.CreativeCloud,
            AdobeLicense.AcrobatDC,
            AdobeLicense.None,
          ].map((license) => {
            const licenseData = getLicenseData(license)

            return (
              <ListItemButton
                selected={selectedLicense === license}
                onClick={() => setSelectedLicense(license)}
                key={license}
              >
                <ListItemIcon>{licenseData.icon}</ListItemIcon>
                <ListItemText
                  primary={licenseData.title}
                  secondary={LICENSE_SELECTION_REASONS[license]}
                ></ListItemText>
              </ListItemButton>
            )
          })}
        </List>
        <Stack spacing={3} direction="row" justifyContent="center">
          <Button onClick={goBack}>Cancel</Button>
          <Button
            disabled={selectedLicense === currentLicense}
            onClick={handleSave}
          >
            Save
          </Button>
        </Stack>
      </Collapse>
      {submitted && (
        <LicenseSubmission
          selectedLicense={selectedLicense}
          handleFinish={handleFinish}
          isLoading={mutation.isLoading}
          isSuccess={mutation.isSuccess}
          isError={mutation.isError}
        />
      )}
    </>
  )
}
