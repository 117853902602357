import {
  Dialog,
  Box,
  Tab,
  Tabs,
  Typography,
  DialogTitle,
  TextField,
  Button,
  Alert,
  DialogActions,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import React from "react"

interface SDLConfirmationDialogProps {
  open: boolean
  setOpen: (open: boolean) => void
  onConfirm: (kfsNumber: string) => void
}

interface LicenseTypePanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const ConfirmationTextField = styled(TextField)({
  "& .MuiInput-underline:after": {
    borderBottomColor: "#E30047",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#5a001c",
    },
    "&:hover fieldset": {
      borderColor: "#88002a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#b50038",
    },
  },
})

function LicenseTypePanel(props: LicenseTypePanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  )
}

function SDLConfirmationDialog(props: SDLConfirmationDialogProps) {
  const { open, setOpen, onConfirm } = props
  const [tabNum, setTabNum] = React.useState(0)
  const [kfsNumber, setKfsNumber] = React.useState("")
  const [showAlert, setShowAlert] = React.useState(false)
  const [isProperKFS, setIsProperKFS] = React.useState(false)
  const [hasFailedOnce, setHasFailedOnce] = React.useState(false)

  const handleTabChange = (event: React.SyntheticEvent, tab: number) => {
    setTabNum(tab)
    setKfsNumber("")
    setIsProperKFS(false)
  }

  const handleClose = () => {
    setOpen(false)
    setKfsNumber("")
    setIsProperKFS(false)
    setHasFailedOnce(false)
  }

  const handleConfirm = () => {
    if (tabNum === 1) {
      onConfirm("")
      handleClose()
    } else if (!isProperKFS) {
      setShowAlert(true)
      setHasFailedOnce(true)
    } else {
      onConfirm(kfsNumber)
      handleClose()
    }
  }

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const re = new RegExp("^[A-Za-z][A-Za-z].{5,}$")
    setKfsNumber(event.target.value)
    if (re.test(event.target.value)) {
      setIsProperKFS(true)
    } else {
      setIsProperKFS(false)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Choose the appropriate Shared Device License</DialogTitle>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabNum} onChange={handleTabChange}>
          <Tab label="Creative Cloud"></Tab>
          <Tab label="Acrobat Pro"></Tab>
        </Tabs>
      </Box>
      <LicenseTypePanel index={0} value={tabNum}>
        <Typography
          sx={{
            mt: "0.5rem",
            p: "0.5rem",
            opacity: "0.75",
          }}
        >
          Adobe Creative Cloud costs $180 and will be billed to the KFS account
          number you'll provide below. You can get this account number from your
          supervisor/office manager/fiscal officer. The license is valid for the
          current fiscal year only and will be renewed annually by your
          department.
        </Typography>
        <ConfirmationTextField
          autoFocus
          margin="dense"
          id="kfs-number"
          label="KFS Number"
          placeholder="The KFS number format is [A-Z][A-Z]#####. (e.g. GF12345)."
          type="text"
          fullWidth
          variant="standard"
          value={kfsNumber}
          required
          error={hasFailedOnce}
          onChange={handleTextChange}
        />
        {showAlert && (
          <Alert severity="error">
            Please use a valid KFS Number and then agree with the Terms of
            Conditions!
          </Alert>
        )}
      </LicenseTypePanel>
      <LicenseTypePanel index={1} value={tabNum}>
        <Typography
          sx={{
            mt: "0.5rem",
            p: "0.5rem",
            opacity: "0.75",
          }}
        >
          This license is provided to your department at no cost.
        </Typography>
      </LicenseTypePanel>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={!(tabNum === 1 || isProperKFS)}
          onClick={handleConfirm}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SDLConfirmationDialog
