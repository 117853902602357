interface UserInfo {
    ucinetid: string
    campus_id: string
    display_name: string
}

export const fetchUserInfo = async () => {
    const res = await fetch("/api/login-check")

    if (!res.ok) {
        throw new Error("API response was not ok!")
    }

    const json = await res.json()

    return json as UserInfo | null
}

export enum AdobeLicense {
    CreativeCloud,
    AcrobatDC,
    SharedDevice,
    None,
}

const LICENSE_MAPPING = {
    creative_cloud: AdobeLicense.CreativeCloud,
    acrobat_dc: AdobeLicense.AcrobatDC,
    shared_device: AdobeLicense.SharedDevice,
    none: AdobeLicense.None,
}

const LICENSE_MAPPING_REVERSE = Object.fromEntries(
    Object.entries(LICENSE_MAPPING).map(([key, value]) => [value, key])
)

export const fetchCurrentLicense = async () => {
    const res = await fetch("/api/current-license")

    if (!res.ok) {
        throw new Error("API response was not ok!")
    }

    const json: { license: keyof typeof LICENSE_MAPPING } = await res.json()

    return LICENSE_MAPPING[json.license]
}

export const checkCsc = async () => {
    const res = await fetch("/api/check-csc")

    if (!res.ok) {
        throw new Error("API response was not ok!")
    }

    const json = await res.json()
    return json
}

export const setLicense = async (params: { license: AdobeLicense, kfsNumber: string }) => {
    const res = await fetch("/api/license", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            requested_license: LICENSE_MAPPING_REVERSE[params.license],
            kfsNumber: params.kfsNumber
        }),
    })

    if (!res.ok) {
        throw new Error("API response was not ok!")
    }

    const json: {
        removed: string[]
        added: string
    } = await res.json()

    return json
}

export interface LicenseEligibility {
    eligible: boolean
    reason: number
    reason_name: string
}

export const fetchEligibility = async () => {
    const res = await fetch("/api/eligibility")

    if (!res.ok) {
        throw new Error("API response was not ok!")
    }

    const json: LicenseEligibility = await res.json()

    return json
}
