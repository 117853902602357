import { Add, Autorenew, InstallDesktop } from "@mui/icons-material"
import {
  List,
  Paper,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Stack,
  Collapse,
  Container,
} from "@mui/material"
import { CoPresent } from "@mui/icons-material"

import React from "react"
import { useMutation, useQuery } from "react-query"

import { AdobeLicense, checkCsc, setLicense } from "../apiFunctions"
import { ChangeLicense } from "./ChangeLicense"
import { LicenseDescription } from "./LicenseDescription"
import SDLConfirmationDialog from "./SDLConfirmationDialog"
import LicenseSubmission from "./LicenseSubmission"

enum View {
  HOME,
  CHANGE_LICENSE,
}

interface LicenseManagementMainProps {
  currentLicense: AdobeLicense
  isCsc: boolean
}

export const LicenseManagementMain = ({
  currentLicense,
  isCsc,
}: LicenseManagementMainProps) => {
  const [currentView, setCurrentView] = React.useState(View.HOME)
  const [sdlDialogOpen, setSdlDialogOpen] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)

  const mutation = useMutation(setLicense)

  return (
    <Container maxWidth="md" sx={{ padding: 3 }}>
      <SDLConfirmationDialog
        open={sdlDialogOpen}
        setOpen={setSdlDialogOpen}
        onConfirm={(kfsNumber: string) => {
          mutation.mutate({
            license: AdobeLicense.SharedDevice,
            kfsNumber: kfsNumber,
          })
          setSubmitted(true)
        }}
      />
      <Collapse in={currentView === View.HOME && !submitted}>
        <Stack spacing={3}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h6">Current License:</Typography>
            <List disablePadding>
              <ListItem>
                <LicenseDescription
                  license={currentLicense}
                ></LicenseDescription>
              </ListItem>
            </List>
          </Paper>
          <List>
            <ListItemButton onClick={() => setCurrentView(View.CHANGE_LICENSE)}>
              <ListItemIcon>
                {currentLicense === AdobeLicense.None ? (
                  <Add></Add>
                ) : (
                  <Autorenew></Autorenew>
                )}
              </ListItemIcon>
              <ListItemText
                primary={
                  currentLicense !== AdobeLicense.None
                    ? "Manage My Named User License (NUL)"
                    : "Get a Named User License (NUL)"
                }
                secondary={
                  currentLicense !== AdobeLicense.None
                    ? "Select this button to order or change your named user license (NUL) for Acrobat Professional or Creative Cloud"
                    : "Click here to get an Adobe Named User License (NUL)"
                }
              ></ListItemText>
            </ListItemButton>
            {isCsc && (
              <ListItemButton onClick={() => setSdlDialogOpen(true)}>
                <ListItemIcon>
                  <CoPresent />
                </ListItemIcon>
                <ListItemText
                  primary={
                    "CSCs Only - Request a Shared Device License (SDL) for a specific machine"
                  }
                  secondary={
                    "Do NOT choose this license unless you are a CSC/local IT support. It will ONLY work on specific machines."
                  }
                />
              </ListItemButton>
            )}

            {currentLicense !== AdobeLicense.None && (
              <ListItemButton
                component="a"
                href="https://uci.service-now.com/sp?id=kb_article&sysparm_article=KB0012283"
                target="_blank"
              >
                <ListItemIcon>
                  <InstallDesktop></InstallDesktop>
                </ListItemIcon>
                <ListItemText
                  primary="Installation / Setup Instructions"
                  secondary="Click here for installation / setup instructions for your Adobe license"
                ></ListItemText>
              </ListItemButton>
            )}
          </List>
        </Stack>
      </Collapse>
      <Collapse in={currentView === View.CHANGE_LICENSE}>
        {currentLicense !== undefined && (
          <ChangeLicense
            currentLicense={currentLicense}
            goBack={() => setCurrentView(View.HOME)}
          ></ChangeLicense>
        )}
      </Collapse>
      {submitted && (
        <LicenseSubmission
          selectedLicense={AdobeLicense.SharedDevice}
          handleFinish={() => {
            setSubmitted(false)
            setCurrentView(View.HOME)
          }}
          isLoading={mutation.isLoading}
          isSuccess={mutation.isSuccess}
          isError={mutation.isError}
        />
      )}
    </Container>
  )
}
